export const INTERSTITIAL_EVERY = 5;
export const INTERSTITIAL_LIMIT = 1000;
export const AD_TIME_LIMIT = 11; // minutes

// %% FEATURE_MODE %%
export const FEATURE_MODE =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'PAID_APP' : 'WEB'; // PAID_APP, FREE_APP, WEB

// %% APP_VERSION %%
export const appVersion =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'DEV' : '3.5.1';

// %% LAUNCH_DATE %%
export const launchDate =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? new Date() - 100
    : 1655785360411;

export const ENC_KEY = '29F8FZ$j$qzv33-J.Qw4%';

export const LS_KEYS = [
  'userId',
  'userName',
  'userNameSet',
  'userAuth',
  'email',
  'isAdmin',
  // 'activeRooms',
  'sounds',
  'lockScreen',
  'freeJoinPublic',
  'shownRatingDate',
  'askedTester',
  'firstInstalled',
  // 'newGameSettings',
  // 'newGameSettingsOnline',
  // 'newGameSettingsOffline',
  'themeMode',
  'language',
  'aiSpeed',
  'emojiTone',
  'endpoint',
  'version3',
  'version3.3',
];

// export const APP_NAME = `Can't Stop, Won't Stop`;
export const COLOURS = {
  0: '#0293ed', // '#28adfd', // blue
  1: '#e83c21', // '#ea4c32', // red
  2: '#38a013', // '#45c617', // green
  3: '#f9a406', // '#f9ac19', // orange
};
export const COLOURS_FADED = {
  0: '#0293ed', // '#28adfd', // blue
  1: '#ff664e', // '#ea4c32', // red
  2: '#38a013', // '#45c617', // green
  3: '#f9a406', // '#f9ac19', // orange
};
export const COLOURS_DARK = {
  0: '#007bc7', // '#28adfd', // blue
  1: '#c92d14', // '#ea4c32', // red
  2: '#2b820c', // '#45c617', // green
  3: '#d48b04', // '#f9ac19', // orange
};
export const LANGUAGES = {
  en: 'English',
  ja: '日本語',
};
export const LANGUAGE_FLAGS = {
  en: 'GB',
  ja: 'JP',
};
export const DRAWER_WIDTH = 260;
export const REACTIONS = {
  smile: {
    emoji: '😊',
    txt: ':)',
  },
  yay: {
    emoji: '🥳',
    txt: 'Yay!',
  },
  phew: {
    emoji: '😅',
    txt: 'Phew!',
  },
  lol: {
    emoji: '😂',
    txt: 'LOL',
  },
  mindblown: {
    emoji: '🤯',
    txt: 'Mind blown!',
  },
  frown: {
    emoji: '🙁',
    txt: ':(',
  },
  cry: {
    emoji: '😢',
    txt: 'Sad',
  },
  wow: {
    emoji: '😮',
    txt: 'Wow!',
  },
  hmm: {
    emoji: '🤔',
    txt: 'Hmm',
  },
  angry: {
    emoji: '🤬',
    txt: 'Grrr',
  },
  shrug: {
    emoji: '🤷',
    tones: ['🤷🏻', '🤷🏼', '🤷🏽', '🤷🏾', '🤷🏿'],
    txt: '*Shrug*',
  },
  omg: {
    emoji: '😱',
    txt: 'OMG!',
  },
  crossed: {
    emoji: '🤞',
    tones: ['🤞🏻', '🤞🏼', '🤞🏽', '🤞🏾', '🤞🏿'],
    txt: 'Please!',
  },
  oops: {
    emoji: '🤦',
    tones: ['🤦🏻', '🤦🏼', '🤦🏽', '🤦🏾', '🤦🏿'],
    txt: 'Oh dear',
  },
  ok: {
    emoji: '👌',
    tones: ['👌🏻', '👌🏼', '👌🏽', '👌🏾', '👌🏿'],
    txt: 'OK!',
  },
  wave: {
    emoji: '👋',
    tones: ['👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿'],
    txt: 'Wave!',
  },
  clap: {
    emoji: '👏',
    tones: ['👏🏻', '👏🏼', '👏🏽', '👏🏾', '👏🏿'],
    txt: 'Nice!',
  },
  thumb: {
    emoji: '👍',
    tones: ['👍🏻', '👍🏼', '👍🏽', '👍🏾', '👍🏿'],
    txt: 'Thumb',
  },
  thumbdown: {
    emoji: '👎',
    tones: ['👎🏻', '👎🏼', '👎🏽', '👎🏾', '👎🏿'],
    txt: 'Thumb down',
  },

  zzz: {
    emoji: '😴',
    txt: 'Zzz',
  },
  giggle: {
    emoji: '🤭',
    txt: 'Hehe',
    // hide: true,
  },
  hug: {
    emoji: '🤗',
    txt: '*Hugs*',
    // hide: true,
  },
  flushed: {
    emoji: '😳',
    txt: 'Embarrassed',
    // hide: true,
  },
  vomit: {
    emoji: '🤢',
    txt: 'Vomit',
    // hide: true,
  },
  roll: {
    emoji: '🙄',
    txt: '*Roll eyes*',
    // hide: true,
  },
  confused: {
    emoji: '😕',
    txt: 'Confused',
    hide: true,
  },
  sob: {
    emoji: '😭',
    txt: 'Sob',
    hide: true,
  },
  xeyes: {
    emoji: '😵',
    txt: 'Xo',
    hide: true,
  },
  grimace: {
    emoji: '😬',
    txt: 'Grimace',
    hide: true,
  },
  halo: {
    emoji: '😇',
    txt: 'Halo',
    hide: true,
  },
  shake: {
    emoji: '🤝',
    txt: '*Hand shake*',
    // hide: true,
  },
  bow: {
    emoji: '🙇',
    tones: ['🙇🏻', '🙇🏼', '🙇🏽', '🙇🏾', '🙇🏿'],
    txt: '*Bow*',
    hide: true,
  },
  no: {
    emoji: '🙅',
    tones: ['🙅🏻', '🙅🏼', '🙅🏽', '🙅🏾', '🙅🏿'],
    txt: 'No',
    hide: true,
  },
  strong: {
    emoji: '💪',
    tones: ['💪🏻', '💪🏼', '💪🏽', '💪🏾', '💪🏿'],
    txt: '*Flex*',
    // hide: true,
  },

  pray: {
    emoji: '🙏',
    tones: ['🙏🏻', '🙏🏼', '🙏🏽', '🙏🏾', '🙏🏿'],
    txt: 'Pray',
    // hide: true,
  },
  handsup: {
    emoji: '🙌',
    tones: ['🙌🏻', '🙌🏼', '🙌🏽', '🙌🏾', '🙌🏿'],
    txt: '*Hands raised*',
    // hide: true,
  },
  eyes: {
    emoji: '👀',
    txt: 'Eyes',
    // hide: true,
  },
  poop: {
    emoji: '💩',
    txt: 'Poop',
    // hide: true,
  },
  fire: {
    emoji: '🔥',
    txt: 'Fire',
    hide: true,
  },
  skull: {
    emoji: '☠️',
    txt: 'Skull',
    hide: true,
  },
  broken: {
    emoji: '💔',
    txt: 'Broken Heart',
    hide: true,
  },
  almost: {
    emoji: '🤏',
    tones: ['🤏🏻', '🤏🏼', '🤏🏽', '🤏🏾', '🤏🏿'],
    txt: 'Almost',
    hide: true,
  },
  whiteflag: {
    emoji: '🏳',
    txt: 'White Flag',
    hide: true,
  },
};
// export const STRINGS = {
//   public_full: 'Public Game',
//   private_full: 'Private Game',
//   public: 'Public',
//   private: 'Private',
// };
export const BASE_UNIT_HEIGHT = 30;
export const COL_WIDTH = 24;

export const FADED = 0.4;

export default '';
