// Every string is in the form:
// key: 'String text',
// The key is in light blue and the string text is in peach.
// Only ever translate the string text in a PEACH colour.
// Also don't edit the {variables} in curly brackets (see below for more info)

// Most strings have green comments on the line above to hopefully help explain and give context.

// *** {0}, {1}, etc ***
// These are variables inserted into the text. The variables will be explained in the line(s) above.

// *** {namedStrings} ***
// There are using other strings from the rest of the file.
// e.g. 'The app is {appName}!' would result in "The app is Can't Stop, Won't Stop"
// If the use of that string doesn't actually work in the local language you can
// just remove the "{namedString}" and write out the full text.

// *** _plural ***
// In English and many other languages there are singular and plural forms of words, such as "1 cat" vs "2 cats".
// When a variable (like {0}) is a number, sometimes there needs to be two strings, such as:
// xCats: '{0} cat', (when {0} is 1)
// xCats_plural: '{0} cats', (when {0} is anything other than 1)
// If the new language doesn't have plurals, you can just delete the lines where the key ends with "_plural".

export default {
  lang: 'ja', // Don't edit this
  appStoreLang: 'jp', // Don't edit this
  playStoreLang: 'ja', // Don't edit this
  dateAt: ' ', // Don't edit this

  // App
  appName: "Can't Stop, Won't Stop",

  // General
  // Question confirming the user wants to do the action
  areYouSure: 'いいですか?',
  notConnected: 'サーバーに接続されていません。',
  // Email button
  emailMe: 'メール',
  // Email Address text field
  // Google Translate
  emailAddress: 'Eメール',
  // Helper text for privacy of email
  // Google Translate
  emailHelper: 'あなたの電子メールはあなたのアカウントを回復するためにのみ使用されます。',
  // When a user is also an admin
  admin: '管理者',
  // The full paid version of the app
  fullEdition: 'フルエディション',
  // The limited free version of the app
  basicEdition: 'ベーシックエディション',
  // The website version of the app
  webEdition: 'Webエディション',
  // An unknown type of user
  unknownUser: '不明なユーザー',
  // Not connected to the internet
  offline: 'オフライン',
  offlineGame: 'オフライン ゲーム',
  offlineGameDesc:
    '"Pass and Play" のオフラインゲームでは同室にいる友達と交代でプレイしたり、練習としてプレイしたりできます。',
  // Button for downloading on the Apple App Store
  downloadAppStore: 'App Storeでダウンロードする',
  // Button for downloading on the Google Play Store
  downloadPlayStore: 'Google Playで取得する',
  // Button to expand the form and show the more advanced options
  showAdvanced: '詳細を表示する',
  // Displayed when the app is connecting to the game server
  connecting: '接続中',
  // Displayed when the app is loading a game or page
  loading: 'ロード中',
  // A star is shown next to players with the best scores
  // Google Translate
  topPlayer: 'トップ・プレイヤー',
  // An icon is shown next to new players
  // Google Translate
  newPlayer: '初心者',

  // AI
  // Google Translate
  aiSpeed: 'AIプレーヤーの速度',
  // Google Translate
  slowSpeed: 'スロー',
  // Google Translate
  normalSpeed: '通常の速度',
  // Google Translate
  fastSpeed: '速い',
  // Google Translate
  instantSpeed: '即時',

  // Google Translate
  human: '人間',
  // Google Translate
  aiSafe: '注意深い',
  // Google Translate
  aiNormal: '正常',
  // Google Translate
  aiRisky: 'リスキーな',
  // Google Translate
  aiCrazy: 'クレイジー',
  // Google Translate
  aiExtreme: '過激',

  ai: 'AI',
  humanFull: '{human}',
  aiSafeFull: '{ai}: {aiSafe}',
  aiNormalFull: '{ai}: {aiNormal}',
  aiRiskyFull: '{ai}: {aiRisky}',
  aiCrazyFull: '{ai}: {aiCrazy}',
  aiExtremeFull: '{ai}: {aiExtreme}',

  // Google Translate
  onlyInFull: '{fullEdition}でのみご利用いただけます。',
  // Google Translate
  onlyInFullBrackets: '({fullEdition}のみ)',

  // MenuPage
  // {0} is the name of the app in bold
  welcomeToApp: 'はじめよう {0}!',
  welcomeToAppDesc:
    '設定メニューからユーザーネームを変更したり、プレイ方法がわからないときにヘルプメニューを開いたりできます。',

  // {0} is the username
  hiUser: 'ハーイ, {0}!',
  // Button to create a new online game
  newOnlineGame: '新しいオンラインゲーム',
  // Button to join an existing online game
  joinOnlineGame: 'オンラインゲームに参加する',
  // Button to start and play a new offline game
  newOfflineGame: 'オフラインゲームをはじめる',
  // Confirming with the user that they want to start a new offline game when they already have an existing game
  newOfflineGameConfirm: 'オフラインゲームをスタートしますか?',
  newOfflineGameConfirmDesc: '現在のオフラインゲームを終了しますか。 {areYouSure}',
  // Button to continue playing an existing offline game
  continueOfflineGame: 'オフラインゲームを続ける',

  // Button to take the user to their active games
  // {0} is the number of active games the user has
  yourXGames: 'ゲーム数は {0} です',
  yourXGames_plural: 'ゲーム数は {0} です',

  // Button to take the user to one specific game
  // {0} is the game ID
  goToRoom: 'ゲームナンバー #{0}',

  // Button to join a specific game (usually by invitation)
  // {0} is the game ID
  joinX: '#{0} に参加する',

  // Button to go to the game rules
  howToPlay: 'プレイ方法',

  // Rules heading
  rules: 'ルール',
  rulesIntro1:
    '順番がまわってきたらプレイヤーは4つのサイコロを振ります。サイコロを2つずつのペアに分け、各ペアの数字を足します。たとえば、１、２、３、６が出たときには、５と７、４と８、３と９の数字ペアが作れます。',
  rulesIntro2:
    'できあがった数字を選び、その数字の列でコマを動かします。一度に動かせるコマの数は限られています。',
  rulesIntro3:
    'サイコロの目を足したペアに合う列がなくなると、おしまいです。そのコマを失います。止まることを選ぶときには、自分の色のマーカーとコマを置きかえます。',
  rulesIntro4:
    '２と１２の列は短いですが、サイコロでその目の組み合わせが出ることはまれです。７の列は長いですが、もっとも出やすいサイコロの目からできあがります。',
  rulesIntro5:
    'プレイヤーが列の先端に到達して止まったら、列を申告してスコアが入ります。残りのゲームでは、その列は使えません。プレイヤーのスコアが目標に届くと、ゲームは終了です。',

  // Sub-heading
  onlineGames: 'オンラインゲーム',
  onlineGamesDesc:
    'オンラインでは他のプレイヤーと自作のパブリックゲームやプライベートゲーム、または最初から入っているゲームで対戦可能です。',
  offlineGames: 'オフラインゲーム ("Pass and Play")',
  offlineGamesDesc: 'オフラインゲームは1つの端末で、また別の端末のプレイヤーとプレイできます。',
  originalRules: 'オリジナルゲームのルール',
  videoIntro:
    'YouTubeでは、もとになったボードゲームについて紹介しています。 もしボードゲームで遊んだことがなければ、参考にしてください:',
  // {0} is the number of the video (e.g. 1 => "Video 1")
  videoX: '動画数 {0}',
  privacyPolicyDesc:
    '個人情報を収集することはありせん。アプリの設定やプレイしたゲームはサーバーに送信されますが、データは一時的に保有するのみで、いかなるサードパーティとも共有はしません。',

  // Game settings
  // {0} is the number of columns
  xColumns: '{0} 列',

  xColumnsAltDesc: '勝つために必要な列数',
  // {0} is the number of columns
  xColumnsDesc: '最初のプレイヤーは {0} 列完了するとゲームに勝ちます。',

  // {0} is the number of markers
  xMarkers: '{0} コマ',

  xMarkersAltDesc: '一回にコマを動かせる最大の列数',
  // {0} is the number of columns
  xMarkersDesc: '一回で進められる最大の数 {0} 列に達しています',

  // Optional game mode 1
  forceMax: 'マーカーを最大限活かす',
  forceMaxDesc:
    'プレイヤーは新しいマーカーが使えるよう、サイコロの目の組み合わせを選ばなければなりません。',

  // Optional game mode 2
  preventSkip: '順番を飛ばされないように',
  preventSkipDesc: 'すべての順番でプレイできるようサイコロを振りましょう。',

  // Optional game mode 3
  preDetermine: 'あらかじめ決まっているサイコロの目',
  preDetermineDesc: 'サイコロを振ると一回ごとにランダムな目が出ます。',

  // Google Translate
  doubleHeight: 'ダブルハイト',
  // Google Translate
  doubleHeightDesc: 'すべての列の高さが2倍になり、ゲームが長くなります。',

  public: 'パブリック',
  public_full: 'パブリックゲーム',
  publicDesc: 'パブリックゲームは公開され、誰でも参加可能です。',

  private: 'プライベート',
  private_full: 'プライベートゲーム',
  privateDesc: 'プライベートゲームは、ルームコードを持っている場合にのみ入室可能です。',

  // Heading for the player types (which version of the app each player is using)
  userTypes: 'プレイヤータイプ',

  // Game Info
  // {0} is the game ID
  currentGameX: '現在のゲーム #{0}',
  // {0} is a relative time in the past, for example "5 minutes ago"
  lastActivityX: '最近のアクティビティ {0}',

  // Alert message shown when the user presses the button to copy something to the clipboard
  gameIdCopied: '{gameId} をクリップボードにコピーしました!',
  websiteCopied: 'Webサイトをクリップボードにコピーしました!',

  // Waiting
  // {0} is the number of players joined so far
  // {1} is the total number of players that are needed for the game to start
  xOfYPlayersJoined: ' {1} 人 中 {0} 人のプレイヤーが参加しています:',

  // Button to fully cancel/end the game
  cancelGame: 'ゲームをやめる',
  // Confirmation about cancelling the game before it starts
  // Google Translate
  cancelGameDesc: 'これにより、全員のゲームがキャンセルされます。 本気ですか？',

  // Google Translate
  declineRematch: '断る',
  // Google Translate
  viewPrevious: '前のゲームを見る',

  // Share App
  // Google Translate
  shareMessage: `🎲🎲 私に加わって、中毒性のあるダイスゲームであるCa n't Stopをプレイしてください！ 🎲🎲`,

  // QR linked devices
  // Google Translate
  linkToWeb: 'リンクデバイス',
  // Google Translate
  linkToWebDesc: '{webEdition}でゲームを表示してプレイする',

  // Button to leave the game when waiting for other players to join
  leaveGame: 'ゲームには参加しない',

  // Button to join a game
  joinGame: 'ゲームに参加する',
  // Button to join a rematch game
  // Google Translate: just "Rematch"
  joinRematch: '再戦',

  // {0} is the website URL
  inviteAtX: '友達を無料で参加できる {0}に招待する',

  // Join
  gameId: 'ゲームID',
  gameIdHash: '{gameId} ナンバーは',
  join: '参加する',
  enterGameId: ' {gameId} を入力して友達のプライベートゲームに参加する',
  // {0} is the name of the app
  listGamesFullApp: 'パブリックゲームのリストを閲覧するには {0} アプリが必要です:',
  noPublicGames: '現在はアクティブなパブリックゲームはありません。',
  newGameFullApp:
    '新しいゲームを作成して無料で参加できる ({basicEdition} のアプリか {webEdition}) に招待しよう!',
  publicGames: 'パブリックゲーム',
  waiting: '待機中',
  publicGamesWaiting: '{publicGames}: {waiting}',
  inProgress: '進行中',
  publicGamesInProgress: '{publicGames}: {inProgress}',
  recentlyFinished: '最近終了',
  publicGamesRecentlyFinished: '{publicGames}: {recentlyFinished}',

  // New Game
  createOnlineGame: 'オンラインゲーム作成',
  createOnlineGameDesc:
    'オンラインゲームを作成したら、友達を招待するか、他のプレイヤーの参加を待ちましょう!',
  createOfflineGame: 'オフラインゲーム作成',
  createOfflineGameDesc:
    '"Pass and Play" できるオフラインゲームを作成して、遊びに来た友達と交代でプレイしよう。練習プレイとしても利用できます。 ',
  // {0} is the name of the app
  noNewGameWeb:
    '{0} の Webエディション は、ゲームIDで参加した場合にのみ利用できます。新しいゲームを作成するには、アプリが必要です:',
  // {0} is the name of the app
  noNewGameBasic:
    '{0} の ベーシックエディション では、ゲーム参加とオフラインゲーム作成が可能です。新しいオンラインゲームを作成したい場合には、フルバーションのアプリをご購入ください:',
  // Google Translate
  basicLimitMessage:
    '{0}の基本版では、デフォルト設定で一度に作成できるゲームは5つだけです。 オプションを変更してゲームを作成するには、フルエディションが必要です:',

  // {0} is the number of players
  xPlayers: '{0} 人のプレイヤー',
  xPlayersDesc: 'ゲームに参加中のプレイヤー数',

  // Conceding
  // Google Translate
  concede: '譲る',
  // Google Translate
  conceded: '敗北を認めた',
  // Google Translate
  xConceded: '{0}は降伏しました。',
  // Google Translate
  xGamesConceded: '{0}は降伏しました ({1}%)',

  // Google Translate
  xGamesPlayed: '完了した{0}つのゲーム',
  // Google Translate
  xGamesPlaying: '現在の{0}つのゲーム',

  // Google Translate
  // xStopRate: '最近：{1}％/全体：{0}％',
  xStopRate: 'ストップレート',
  // Google Translate
  xStopRateDesc: '（ストップで終わるターン）',

  // Google Translate
  vsFormDesc: 'あなたの最近の結果対{0}',

  // Google Translate
  recent: '最近',
  // Google Translate
  allTime: 'いつも',

  // Google Translate
  youConceded: 'あなたは降伏しました。',
  // Google Translate
  concedeCheck: 'このゲームを放棄してもよろしいですか？ これは元に戻せません。',

  // Rematch
  // Google Translate
  rematch: '再戦',
  // Google Translate
  invited: '招待',
  // Google Translate
  rematchProposed: '再戦',

  // Replay from here button
  // Google Translate
  replay: 'ここから再生',

  // Button to stop the replay
  // Google Translate
  stopReplay: '再生を停止します',

  // Recent form: Win
  // Google Translate
  formWin: '勝利',
  // Recent form: Loss
  // Google Translate
  formLoss: '損失',
  // Recent form: Conceded
  // Google Translate: SAME AS LOSS
  formConceded: '損失',

  // Google Translate
  recentForm: '最近のフォーム',

  // Game Modes

  // The classic normal rules of the game
  classic: 'クラシック',
  classicFull: 'クラシックモード',
  classicDesc: 'クラシックのルール',

  // The "blocking" variant of the game
  block: 'ブロック',
  blockFull: '"ブロック" バリアント',
  blockDesc: '別のプレイヤーがブロックしているのと同じ場所で止まります。',

  // The "Jumping" variant of the game
  jump: 'ジャンプ',
  jumpFull: '"ジャンプ" バリアント',
  jumpDesc:
    'プレイヤーが同じ場所にとどまることがないように、別のプレイヤーをジャンプして飛び越えます。',

  // Game
  youWin: '勝利!',
  youWon: '勝利!',
  // {0} is the winning player name
  xWins: '{0} が勝ちました!',
  // Same as above for now
  xWon: '{0} が勝ちました!',
  youLost: '終了', // finished
  // Google Translate
  xWinRate: '勝率{0}%',

  // Google Translate
  // Neither player won
  neither: '別のプレイヤー',

  // Alert when there are no more possible moves
  noPossibleMoves: 'これ以上動かせません。',

  // Button to stop and pass to the next player
  stop: 'ストップ',

  gameOver: 'ゲームオーバー!',
  // Notification and history
  gameStarted: 'プレイする', // Currently same as newGame. Needs changing

  // Button to start a new game
  newGame: 'プレイする',
  // Button to end the game early
  endGame: 'ゲームを終わる',
  startGame: 'ゲームをはじめる',
  // Google Translate
  nextGame: '次のゲーム',
  // Confirmation about ending the game early
  endGameEarly: 'ゲームを抜けますか?',
  endGameEarlyDesc: '全員が現在のゲームを終了しますが、よろしいですか?',

  yourTurn: 'あなたの番',
  // {0} is the player's name
  xTurn: '{0}の番です',
  // The status of a finished game
  finished: '終了',
  // The status of a game in progress
  playing: 'プレイ中',
  // {0} is the number of players
  waitingForX: '待ち人数 {0}',
  // {0} is the number of players
  waitingForXPlayers: '{0} 人のプレイヤーを待っています',

  // {0} is a relative time in the past, for example "2 days ago"
  createdX: '{0}に作成',

  // New version
  // Google Translate
  newVersion: '新しいバージョンが利用可能',
  // Google Translate
  newVersionDesc: 'これでバージョン{0}をダウンロードする準備が整いました。',

  // Beta Tester
  becomeTester: 'テスターになりませんか?',
  becomeTesterDesc: '新しい機能を試したい方は、ボランティアのテスター希望とメールしてください!',
  // Button to decline joining as a tester
  noThanks: '希望しません!',

  // Warning message when push notification permissions are denied
  // Google Translate
  pushDeniedTitle: 'プッシュ通知はオフです。',
  // Google Translate
  pushDeniedDesc: 'あなたの番になるとアラートは表示されません。',

  // Settings
  settings: '設定',
  // Button to set/save the player's username
  set: '確定',
  // To change if the app will make sounds or not
  sounds: 'サウンド',
  // The player's name visible to others online
  username: 'ユーザーネーム',
  // The app's language
  language: '言語',
  // Lock screen awake
  // Google Translate
  lockScreen: 'ゲーム中に画面をオフにしないでください',

  // Button to show more of the history moves
  // Google Translate
  showMore: 'もっと見せる',

  // User Key (for switching accounts)
  // Google Translate
  userKey: 'ユーザーアカウントキー',
  // Google Translate
  userKeyDesc: `警告：これは、自分が何をしているかを知っている場合にのみ使用してください。 誰とも共有しないでください。`,

  // Send email requesting deletion of user account
  // Google Translate
  requestDeleteAccount: 'アカウントの削除をリクエストする',

  // Show last turn in the display
  // Google Translate
  lastTurnSetting: `前のターンを視覚化する`,

  // Google Translate
  includeLostGames: '次のゲームボタンに失われたゲームを含める',

  you: '君は',

  // Your Games
  yourGames: 'ゲーム',
  notInGames: '現在アクティブなゲームはありません。',

  // Errors
  // Google Translate
  waitingLimit: 'まだ開始を待っている新しいゲームの制限に達しました。',
  // Google Translate
  basicLimit: '{basicEdition}では、一度に作成できるゲームは5つだけです。',
  // Google Translate
  serverTimeout: 'サーバーのタイムアウト',
  // Google Translate
  samePlayersLimit: '同じプレイヤーでアクティブなゲームの制限に達しました。',

  // Bot check
  // Google Translate
  typeOk: '続行するには、下に「ok」と入力してください...',

  // Error page
  somethingWrong: '問題が発生しました!',
  somethingWrongDesc:
    '恐れ入りますが、エラー情報と何を操作をしていたかなど、簡単な説明を添えたメールをお寄せください。迅速に修正します。:',

  // Common app localisations:
  privacyPolicy: 'プライバシーポリシー',
  // Link to support the developer
  supportDeveloper: '開発者にコーヒーをプレゼントするには',

  // Button to say no
  no: 'No',
  // Button to say yes
  yes: 'Yes',
  // Close/exit button
  close: '閉じる',
  // Button to continue playing
  continue: '続行',
  comingSoon: 'Coming soon!',
  reset: 'リセット', // From Skincare app
  ok: 'はい',

  // The app's colour theme (light/dark)
  appTheme: 'アプリのテーマ',
  appThemeSystem: 'デフォルト',
  appThemeLight: 'ブライトモード',
  appThemeDark: 'ダークモード',

  // Screenshot text
  // Each screenshot has text above and text below, which is why each of the below are in 2:
  screenshotPlayAgainst: `"Can't Stop" ダイスゲーム`,
  screenshotPlayAgainst2: '友達とオンラインやオフラインで対戦',
  screenshotCreateOnline: 'オンラインゲームを作成',
  screenshotCreateOnline2: 'ジャンプ & ブロック バリアントあり!',
  screenshotPlayFriends: '友達と遊べる',
  screenshotPlayFriends2: 'オンラインプレイは最大４プレイヤー!',
  screenshotJoinPublicPrivate: 'パブリックゲームやプライベートゲームに参加しようJ',
  screenshotJoinPublicPrivate2: 'オンラインで他の人とプレイもできる!',
  screenshotOffline: '友達と交代で Pass＆Play',
  screenshotOffline2: '友達とオフラインゲームをプレイしよう',
  screenshotInvite: '友達を無料プレイに招待できる',
  screenshotInvite2: 'ベーシックエディションかwebエディションが利用可能',
  screenshotLimitedFree: 'フリーエディションは機能に制限があります',
  screenshotLimitedFree2: '広告あり & オンラインゲームは作成できません',
  screenshotSettings: '設定変更',
  screenshotSettings2: 'ブライトモード ＆ ダークモードに切り替え可',

  // App listing
  appStoreNameFull: "Can't Stop: ダイスゲーム サイコロゲーム", // Maximum 50 characters
  appStoreSubtitleFull: '友達とサイコロ振って遊ぼう!!', // Maximum 80 characters
  appStoreDescriptionFull: `ボードゲーム "Can't Stop(キャントストップ)" をベースにした人気のダイスゲームです。 オンラインまたはオフラインで（交代で)プレイできます。 プレイヤー数は2~4人。

    いろいろなバリエーションを試すか、クラシックバージョンでプレイ!
    
    アプリを持っていない友達もゲームに参加できます。新しいゲームを作成して、オフラインゲームでプレイしましょう。`,

  appStoreNameBasic: "Can't Stop: ダイスゲーム (ベーシック)", // Maximum 50 characters
  appStoreSubtitleBasic: '友達と無料でプレイして自由にサイコロを振ろう!!', // Maximum 80 characters
  appStoreDescriptionBasic: `有名なダイスゲーム "Can't Stop" をベースにしたベーシックエディションのゲームアプリです。オンラインでプレイするか、オフラインで(交代して）遊べます。２〜４人プレイヤー。

    いろいろなバリエーションを試してみるか、クラシックバージョンでお楽しみください!
    
    このアプリでは、新しいオンラインゲームを作成する必要があります。`,
};
