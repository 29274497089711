// Every string is in the form:
// key: 'String text',
// The key is in light blue and the string text is in peach.
// Only ever translate the string text in a PEACH colour.
// Also don't edit the {variables} in curly brackets (see below for more info)

// Most strings have green comments on the line above to hopefully help explain and give context.

// *** {0}, {1}, etc ***
// These are variables inserted into the text. The variables will be explained in the line(s) above.

// *** {namedStrings} ***
// There are using other strings from the rest of the file.
// e.g. 'The app is {appName}!' would result in "The app is Can't Stop, Won't Stop"
// If the use of that string doesn't actually work in the local language you can
// just remove the "{namedString}" and write out the full text.

// *** _plural ***
// In English and many other languages there are singular and plural forms of words, such as "1 cat" vs "2 cats".
// When a variable (like {0}) is a number, sometimes there needs to be two strings, such as:
// xCats: '{0} cat', (when {0} is 1)
// xCats_plural: '{0} cats', (when {0} is anything other than 1)
// If the new language doesn't have plurals, you can just delete the lines where the key ends with "_plural".

export default {
  lang: 'en', // Don't edit this
  appStoreLang: 'us', // Don't edit this
  playStoreLang: 'en', // Don't edit this
  dateAt: ' at ', // Don't edit this
  // App
  appName: "Can't Stop, Won't Stop",

  // General
  // Question confirming the user wants to do the action
  areYouSure: 'Are you sure?',
  notConnected: 'You are not currently connected to the server.',
  // Email button
  emailMe: 'Email Me',
  // Email Address text field
  emailAddress: 'Email Address',
  // Helper text for privacy of email
  emailHelper: 'Your email will only be used for recovering your account.',
  // When a user is also an admin
  admin: 'Admin',
  // The full paid version of the app
  fullEdition: 'Full Edition',
  // The limited free version of the app
  basicEdition: 'Basic Edition',
  // The website version of the app
  webEdition: 'Web Edition',
  // An unknown type of user
  unknownUser: 'Unknown User',
  // Not connected to the internet
  offline: 'Offline',
  offlineGame: 'Offline Game',
  offlineGameDesc:
    'A "Pass and Play" offline game to play with friends in the same room or just to practice against the AI.',
  // Button for downloading on the Apple App Store
  downloadAppStore: 'Download on the App Store',
  // Button for downloading on the Google Play Store
  downloadPlayStore: 'Get it on Google Play',
  // Button to expand the form and show the more advanced options
  showAdvanced: 'Show Advanced',
  // Displayed when the app is connecting to the game server
  connecting: 'Connecting',
  // Displayed when the app is loading a game or page
  loading: 'Loading',
  // A star is shown next to players with the best scores
  topPlayer: 'Top Player',
  // An icon is shown next to new players
  newPlayer: 'New Player',

  // AI
  aiSpeed: 'AI Player Speed',
  slowSpeed: 'Slow',
  normalSpeed: 'Normal',
  fastSpeed: 'Fast',
  instantSpeed: 'Instant',

  human: 'Human',
  aiSafe: 'Safe',
  aiNormal: 'Normal',
  aiRisky: 'Risky',
  aiCrazy: 'Crazy',
  aiExtreme: 'Extreme',
  ai: 'AI',
  humanFull: '{human}',
  aiSafeFull: '{ai}: {aiSafe}',
  aiNormalFull: '{ai}: {aiNormal}',
  aiRiskyFull: '{ai}: {aiRisky}',
  aiCrazyFull: '{ai}: {aiCrazy}',
  aiExtremeFull: '{ai}: {aiExtreme}',

  onlyInFull: 'Only available in the {fullEdition}.',
  onlyInFullBrackets: '({fullEdition} only)',

  // MenuPage
  // {0} is the name of the app in bold
  welcomeToApp: 'Welcome to {0}!',
  welcomeToAppDesc:
    "Use the settings menu to change your username and the help menu if you're not sure how to play.",
  freeJoinPublic:
    "UPDATE: Players with the {basicEdition} can now join public games! Tap '{joinOnlineGame}' below...",

  // {0} is the username
  hiUser: 'Oh hi, {0}!',
  hiUser2: 'Welcome, {0}!',
  hiUser3: 'Hi there, {0}!',
  hiUser4: 'Hey, {0}!',
  hiUser5: 'Hello, {0}!',
  // Button to create a new online game
  newOnlineGame: 'Create Online Game',
  // Button to join an existing online game
  joinOnlineGame: 'Join Online Game',
  // Button to start and play a new offline game
  newOfflineGame: 'New Offline Game',
  // Confirming with the user that they want to start a new offline game when they already have an existing game
  newOfflineGameConfirm: 'New Offline Game?',
  newOfflineGameConfirmDesc: 'This will end the current offline game. {areYouSure}',
  // Button to continue playing an existing offline game
  continueOfflineGame: 'Continue Offline Game',

  // Button to take the user to their active games
  // {0} is the number of active games the user has
  yourXGames: 'Your {0} Game',
  yourXGames_plural: 'Your {0} Games',

  // Button to take the user to one specific game
  // {0} is the game ID
  goToRoom: 'Go To #{0}',

  // Button to join a specific game (usually by invitation)
  // {0} is the game ID
  joinX: 'Join #{0}',

  // Button to go to the game rules
  howToPlay: 'How to play',

  // Rules heading
  rules: 'Rules',
  rulesIntro1:
    'On each turn, the player rolls the four dice, then divides them into two pairs, adding up each pair. For example, a player rolling a 1, 2, 3, and 6 could group them as 5 & 7, 4 & 8, or 3 & 9.',
  rulesIntro2:
    'Choose a pairing to move your markers on the corresponding columns. You only have a limited number of markers per turn.',
  rulesIntro3:
    'If there are no possible dice pairings then your go ends and the markers are lost. If you choose to stop, your markers will be replaced with your colour.',
  rulesIntro4:
    'The 2 and 12 columns are short but rolling those values is rare, whereas the 7 column is the longest but most common.',
  rulesIntro5:
    'Once a player reaches the top of a column and stops, they claim the column and it is added to their score. That column can no longer be played for the rest of the game. Once a player reaches the target score, the game ends.',

  // Sub-heading
  onlineGames: 'Online Games',
  onlineGamesDesc:
    'You can play online against other players either by creating your own public or private game or by joining an existing game.',
  offlineGames: 'Offline Games ("Pass and Play")',
  offlineGamesDesc:
    'You can play an offline game with one device and pass the device between players.',
  originalRules: 'Original Game Rules',
  videoIntro:
    "Here are a couple of YouTube videos explaining the original board game, in case you've never played it:",
  // {0} is the number of the video (e.g. 1 => "Video 1")
  videoX: 'Video {0}',
  privacyPolicyDesc:
    'None of your personal data is collected. Your app settings and the games you play are sent to a server but the data is only kept temporarily and is never shared with any third party.',

  // Game settings
  // {0} is the number of columns
  xColumns: '{0} Column',
  xColumns_plural: '{0} Columns',
  xColumnsAltDesc: 'Number of columns required to win.',
  // {0} is the number of columns
  xColumnsDesc: 'The first player to complete {0} column will win the game.',
  xColumnsDesc_plural: 'The first player to complete {0} columns will win the game.',

  // {0} is the number of markers
  xMarkers: '{0} Marker',
  xMarkers_plural: '{0} Markers',
  xMarkersAltDesc: 'Maximum number of columns per go.',
  // {0} is the number of columns
  xMarkersDesc: 'You can occupy a maximum of {0} column per go.',
  xMarkersDesc_plural: 'You can occupy a maximum of {0} columns per go.',

  // Optional game mode 1
  forceMax: 'Force maximum markers',
  forceMaxDesc: 'Player must choose a dice combination that uses the most new markers.',

  // Optional game mode 2
  preventSkip: 'Prevent skipped turns',
  preventSkipDesc: 'Roll again so that a player never loses their whole turn.',

  // Optional game mode 3
  preDetermine: 'Pre-determine next dice roll',
  preDetermineDesc:
    'The next dice numbers are randomly rolled before you tap {continue} or {stop}.',

  doubleHeight: 'Double Height',
  doubleHeightDesc: 'All columns are twice the height so games are longer.',

  public: 'Public',
  public_full: 'Public Game',
  publicDesc: 'Public games can be seen and joined by anyone.',

  private: 'Private',
  private_full: 'Private Game',
  privateDesc: 'Private games can only be joined using the room code.',

  // Heading for the player types (which version of the app each player is using)
  userTypes: 'Player Types',

  // Game Info
  // {0} is the game ID
  currentGameX: 'Current Game #{0}',
  // {0} is a relative time in the past, for example "5 minutes ago"
  lastActivityX: 'Last activity {0}',

  // Alert message shown when the user presses the button to copy something to the clipboard
  gameIdCopied: '{gameId} copied to the clipboard!',
  websiteCopied: 'Website copied to the clipboard!',

  // Waiting
  // {0} is the number of players joined so far
  // {1} is the total number of players that are needed for the game to start
  xOfYPlayersJoined: '{0} of {1} players have joined:',

  // Button to fully cancel/end the game
  cancelGame: 'Cancel Game',
  // Confirmation about cancelling the game before it starts
  cancelGameDesc: 'This will cancel the game for everyone. Are you sure?',

  declineRematch: 'Decline Rematch',
  viewPrevious: 'View Previous Game',

  // Share App
  shareMessage: `🎲🎲 Join me and play Can't Stop, the addictive dice game! 🎲🎲`,

  // QR linked devices
  linkToWeb: 'Link to Web',
  linkToWebDesc: 'View & play your games on the {webEdition}.',

  // Button to leave the game when waiting for other players to join
  leaveGame: 'Leave Game',

  // Button to join a game
  joinGame: 'Join Game',
  // Button to join a rematch game
  joinRematch: 'Join Rematch',

  // {0} is the website URL
  inviteAtX: 'Invite friends to join for free at {0}',

  // Join
  gameId: 'Game ID',
  gameIdHash: '{gameId} #',
  join: 'Join',
  enterGameId: "Enter the {gameId} above to join a friend's private game.",
  // {0} is the name of the app
  listGamesFullApp:
    "If you want to be able to see a list of public games, you'll need the {0} app:",
  noPublicGames: 'There are currently no public games to join.',
  newGameFullApp:
    'Why not create a new game and invite your friends to join for free (with the {basicEdition} app or the {webEdition})?',
  publicGames: 'Public Games',
  waiting: 'Waiting',
  publicGamesWaiting: '{publicGames}: {waiting}',
  inProgress: 'In Progress',
  publicGamesInProgress: '{publicGames}: {inProgress}',
  recentlyFinished: 'Recently Finished',
  publicGamesRecentlyFinished: '{publicGames}: {recentlyFinished}',

  // New Game
  createOnlineGame: 'Create Online Game',
  createOnlineGameDesc:
    'Create an online game and then either invite your friends or wait for other players to join!',
  createOfflineGame: 'Create Offline Game',
  createOfflineGameDesc:
    'Create a "Pass and Play" offline game to play with friends in the same room or just to practice.',
  // {0} is the name of the app
  noNewGameWeb:
    "The Web Edition of {0} only allows you to join games by their ID. If you want to create new games, you'll need the app:",
  // {0} is the name of the app
  noNewGameBasic:
    "The Basic Edition of {0} only allows you to join games and create offline games. If you want to a create new online game, you'll need the ad-free full app:",
  basicLimitMessage:
    "The Basic Edition of {0} only allows you to create five games at a time with limited settings. If you want to change the options or create more games, you'll need the ad-free full app:",
  // {0} is the number of players
  xPlayers: '{0} Players',
  xPlayersDesc: 'Number of players in the game.',

  // Conceding
  concede: 'Concede',
  conceded: 'Conceded',
  xConceded: '{0} conceded.',
  youConceded: 'You conceded.',
  xGamesConceded: '{0} game conceded ({1}%)',
  xGamesConceded_plural: '{0} games conceded ({1}%)',
  xGamesPlayed: '{0} completed game',
  xGamesPlayed_plural: '{0} completed games',
  xGamesPlaying: '{0} active game',
  xGamesPlaying_plural: '{0} active games',
  // xStopRate: 'Recent: {1}% / Overall: {0}%',
  xStopRate: "{0}'s Stop Rate",
  xStopRateDesc: '(turns ending with a stop)',

  vsFormDesc: 'Your recent results against {0}',

  recent: 'Recent',
  allTime: 'All Time',

  concedeCheck:
    "Are you sure you want to concede this game? It can't be undone and it may affect your score/status.",

  // Rematch
  rematch: 'Rematch',
  invited: 'Invited',
  rematchProposed: 'Rematch Proposed',

  // Replay from here button
  replay: 'Replay From Here',
  // Button to stop the replay
  stopReplay: 'Stop Replay',

  // Recent form: Win
  formWin: 'Win',
  // Recent form: Loss
  formLoss: 'Loss',
  // Recent form: Conceded
  formConceded: 'Conceded',

  recentForm: "{0}'s Recent Form",

  // Game Modes

  // The classic normal rules of the game
  classic: 'Classic',
  classicFull: 'Classic Mode',
  classicDesc: 'The classic rules.',

  // The "blocking" variant of the game
  block: 'Blocking',
  blockFull: '"Blocking" Variant',
  blockDesc: 'Stopping at any of the same positions as another player is blocked.',

  // The "Jumping" variant of the game
  jump: 'Jumping',
  jumpFull: '"Jumping" Variant',
  jumpDesc:
    'Automatically jump past other players so that players are never in the same positions.',

  // Game
  youWin: 'You Win!',
  youWon: 'You Won!',
  // {0} is the winning player name
  xWins: '{0} Wins!',
  xWon: '{0} Won',
  youLost: 'You Lost',
  xWinRate: '{0}% win rate',
  neither: 'Neither',

  // Alert when there are no more possible moves
  noPossibleMoves: 'No possible moves',

  // Button to stop and pass to the next player
  stop: 'Stop',

  gameOver: 'Game Over!',
  // Button to start a new game
  newGame: 'New Game',
  // Notification and history
  gameStarted: 'Game started!',
  // Button to end the game early
  endGame: 'End Game',
  startGame: 'Start Game',
  nextGame: 'Next Game',

  // Confirmation about ending the game early
  endGameEarly: 'End Game Early?',
  endGameEarlyDesc: 'This will end the current game for everyone. Are you sure?',

  yourTurn: 'Your Turn',
  // {0} is the player's name
  xTurn: "{0}'s Turn",
  // The status of a finished game
  finished: 'Finished',
  // The status of a game in progress
  playing: 'Playing',
  // {0} is the number of players
  waitingForX: 'Waiting for {0}',
  // {0} is the number of players
  waitingForXPlayers: 'Waiting for {0} player',
  waitingForXPlayers_plural: 'Waiting for {0} players',
  // {0} is a relative time in the past, for example "2 days ago"
  createdX: 'Created {0}',

  // New version
  newVersion: 'New Version Available',
  newVersionDesc: 'Version {0} is ready to download now.',

  // Beta Tester
  becomeTester: 'Become a tester?',
  becomeTesterDesc:
    "If you'd like to try new features early, please email me about joining the group of volunteer testers!",
  // Button to decline joining as a tester
  noThanks: 'No Thanks!',

  // Warning message when push notification permissions are denied
  pushDeniedTitle: 'Push Notifications are currently turned off for this app.',
  pushDeniedDesc:
    "Without them, you won't be alerted when it's your turn and you risk automatically conceding games.",

  // Settings
  settings: 'Settings',
  // Button to set/save the player's username
  set: 'Set',
  // To change if the app will make sounds or not
  sounds: 'Sounds',
  // The player's name visible to others online
  username: 'Username',
  // The app's language
  language: 'Language',
  // Lock screen awake
  lockScreen: 'Keep screen awake during games',

  // Button to show more of the history moves
  showMore: 'Show More',

  // User Key (for switching accounts)
  userKey: 'User Account Key',
  userKeyDesc: `Warning: only use this if you know what you're doing. Don't share it with anyone.`,

  // Send email requesting deletion of user account
  requestDeleteAccount: 'Request Account Deletion',

  // Show last turn in the display
  lastTurnSetting: `Visualize each player's previous turn`,

  includeLostGames: 'Include lost games in the Next Game button',

  you: 'You',

  // Your Games
  yourGames: 'Your Games',
  notInGames: "You aren't currently in any active games.",

  // Errors
  waitingLimit: 'You have reached your limit of new games that are still waiting to start.',
  basicLimit: 'The {basicEdition} only allows you to create five games at one time.',
  serverTimeout: 'Server timeout. Try again.',
  samePlayersLimit:
    'You have reached the limit for the number of active games with the exact same players.',

  // Bot check
  typeOk: 'Please type "ok" below to continue...',

  // Error page
  somethingWrong: 'Something has gone wrong!',
  somethingWrongDesc:
    'Sorry! Please can you email me with the following information & a brief description of what you were doing and I will fix it ASAP:',

  // Common app localisations:
  privacyPolicy: 'Privacy Policy',
  // Link to support the developer
  supportDeveloper: 'Buy developer a coffee',

  // Button to say no
  no: 'No',
  // Button to say yes
  yes: 'Yes',
  // Close/exit button
  close: 'Close',
  // Button to continue playing
  continue: 'Continue',
  comingSoon: 'Coming soon!',
  reset: 'Reset',
  ok: 'OK',

  // The app's colour theme (light/dark)
  appTheme: 'App Theme',
  appThemeSystem: 'System Default',
  appThemeLight: 'Light Mode',
  appThemeDark: 'Dark Mode',

  // Screenshot text
  // Each screenshot has text above and text below, which is why each of the below are in 2:
  screenshotPlayAgainst: `"Can't Stop" Dice Game`,
  screenshotPlayAgainst2: 'Play Against Friends, Online & Offline',
  screenshotCreateOnline: 'Create Online Games',
  screenshotCreateOnline2: 'Including "Jumping" & "Blocking" variants!',
  screenshotPlayFriends: 'Play With Friends',
  screenshotPlayFriends2: 'Up to 4 Players Online!',
  screenshotJoinPublicPrivate: 'Join Public or Private Games',
  screenshotJoinPublicPrivate2: 'Play against strangers too!',
  screenshotOffline: "Pass 'n' Play",
  screenshotOffline2: 'Play Offline Games With Friends',
  screenshotInvite: 'Invite Friends to Play for Free',
  screenshotInvite2: 'Using the Basic Edition app or Web Edition',
  screenshotLimitedFree: 'Limited Features with Basic Edition',
  screenshotLimitedFree2: "Ad-supported & can't create online games.",
  screenshotSettings: 'Change Settings',
  screenshotSettings2: 'Including light & dark modes',

  // App listing
  appStoreNameFull: "Can't Stop: Dice Game", // Maximum 50 characters
  appStoreSubtitleFull: 'Play your friends and roll the dice!', // Maximum 80 characters
  appStoreDescriptionFull: `Fully-featured popular dice game based on "Can't Stop" board game. Play online or offline (Pass and Play). 2-4 players.

    Try out different variations or stick to the classic version!
    
    Your friends can join your game even if they don't have the app. The app is needed to create new games and play offline games.`,

  appStoreNameBasic: "Can't Stop: Dice Game (Basic)", // Maximum 50 characters
  appStoreSubtitleBasic: 'Play your friends and roll the dice for free!', // Maximum 80 characters
  appStoreDescriptionBasic: `This is the Basic Edition of the popular dice game based on "Can't Stop" board game. Play online or offline (Pass and Play). 2-4 players.

    Try out different variations or stick to the classic version!
    
    The full app is needed to create new online games.`,
};
