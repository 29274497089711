import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWrapper from './AppWrapper';
import * as serviceWorker from './serviceWorker';

// import { setConfig } from 'react-hot-loader';
import ErrorBoundary from './ErrorBoundary';
// import { isAndroid } from './utils';
// setConfig({ errorReporter: ErrorBoundary });

let banner;

try {
  if (!window.cordova) {
    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary>
          <AppWrapper />
        </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  } else {
    document.addEventListener(
      'deviceready',
      () => {
        ReactDOM.render(
          <React.StrictMode>
            <ErrorBoundary>
              <AppWrapper />
            </ErrorBoundary>
          </React.StrictMode>,
          document.getElementById('root'),
        );
        // try {
        //   await admob.start();
        //   banner = new admob.BannerAd({
        //     adUnitId: isAndroid()
        //       ? 'ca-app-pub-2706311467290353/3136032059'
        //       : 'ca-app-pub-2379142366574646/4090560077',
        //   });

        //   await banner.show();
        // } catch (e) {
        //   console.log('admob error', e);
        // }
      },
      false,
    );
  }
} catch (error) {
  ReactDOM.render(<div>{JSON.stringify(error)}</div>, document.getElementById('root'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
