import LocalizedStrings from 'react-localization';
// import moment from 'moment';
import ls from 'local-storage';
import en from './locs/en';
import ja from './locs/ja';

const loc = new LocalizedStrings(
  {
    en,
    ja,
  },
  ls('language')
    ? { customLanguageInterface: () => ls('language'), logsEnabled: false }
    : { logsEnabled: false },
  // {
  // customLanguageInterface: () => 'it-IT',
  // },
);
// moment.locale(loc.getLanguage());

// console.log('String count', Object.values(loc.getContent().en).length);
// console.log('Word count', Object.values(loc.getContent().en).join(' ').split(' ').length);

// loc.setContent(
//   Object.assign({}, loc.getContent(), {
//     it: loc.getContent().en.
//   })
// );

loc.format = (k, ...p) => {
  // console.log('L', k, p, loc.getString(`${k}_plural`));
  try {
    if (typeof p === 'undefined' || !Array.isArray(p) || p.length === 0)
      return loc.formatString(loc.getString(k), loc);
    return typeof p[0] === 'number' && p[0] !== 1 && loc.getString(`${k}_plural`)
      ? loc.formatString(loc.getString(`${k}_plural`), ...p)
      : loc.formatString(loc.getString(k), ...p);
  } catch (e) {
    return '?';
  }
};

export default loc;
