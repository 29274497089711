import React, { Suspense, useState, useMemo, useEffect } from 'react';
import ls from 'local-storage';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';
import teal from '@material-ui/core/colors/teal';
/* global cordova:readonly */

const App = React.lazy(() => import('./App'));
export const isDarkMode = (onSuccess) => {
  // console.log('isDarkMode');
  if (
    typeof cordova !== 'undefined' &&
    cordova &&
    cordova.plugins &&
    cordova.plugins.ThemeDetection &&
    typeof cordova.plugins.ThemeDetection.isDarkModeEnabled === 'function'
  ) {
    cordova.plugins.ThemeDetection.isDarkModeEnabled(onSuccess);
  } else {
    onSuccess({ message: 'No Plugin', value: false });
  }
};
const AppWrapper = () => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [modeFromPlugin, setModeFromPlugin] = useState(false);
  const [modeFromSettings, setModeFromSettings] = useState(ls('themeMode') || 'system');
  // console.log('modeFromPlugin', modeFromPlugin);
  // const themeMode = ls('themeMode') || 'system';
  useEffect(() => {
    ls.on('themeMode', setModeFromSettings);
    return () => ls.off('themeMode', setModeFromSettings);
  }, []);

  const isDark = useMemo(() => {
    if (modeFromSettings === 'dark') return true; // app set to dark
    if (modeFromSettings === 'light') return false; // app set to light
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) return true; // css set to dark
    if (modeFromPlugin === false) {
      isDarkMode(setModeFromPlugin);
    }
    if (typeof modeFromPlugin === 'object' && modeFromPlugin.value) return modeFromPlugin.value;
    return false;
  }, [modeFromSettings, modeFromPlugin]);
  // console.log('isDark', isDark);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: isDark ? 'dark' : 'light',
          primary: {
            main: teal[isDark ? 300 : 500],
          },
          secondary: pink,
        },
        overrides: {
          MuiChip: {
            label: {
              // textShadow: '0px 0px 1px black',
              // fontWeight: 500,
            },
          },
        },
      }),
    [isDark],
  );
  theme.overrides.MuiTypography = { root: { color: theme.palette.text.primary } };
  // console.log('theme', theme);

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  }, [theme]);
  return (
    <Suspense
      fallback={
        <Backdrop open>
          <CircularProgress />
        </Backdrop>
      }
    >
      <ThemeProvider theme={theme}>
        <App setThemeMode={setModeFromSettings} />
      </ThemeProvider>
    </Suspense>
  );
};

export default AppWrapper;
