import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import loc from './loc';
import { FEATURE_MODE, appVersion } from './constants';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: false, hideStack: 0 };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  // componentDidCatch(error, info) {
  // }

  render() {
    const { hasError, error, hideStack } = this.state;
    const { children } = this.props;
    const email = 'chris@mento.co.uk';

    if (hasError) {
      // console.log('errorB', [error.message], [error.name], [error.stack]);
      // You can render any custom fallback UI
      let col = 0;
      let line = 0;
      let file = '';
      if (error.stack) {
        const first = error.stack.indexOf('.js:');
        if (first >= 0) {
          const second = error.stack.substr(first + 4).indexOf(':');
          if (second >= 0) {
            line = parseInt(error.stack.substr(first + 4, second), 10);
            col = parseInt(error.stack.substr(first + second + 5), 10);
            // if (Number.isNaN(col)) col = error.stack;
          }
        }
        const fpos = error.stack.indexOf('/js/');
        if (fpos >= 0) {
          const fpos2 = error.stack.substr(fpos + 4).indexOf('.');
          if (fpos2 >= 0) {
            file = error.stack.substr(fpos + 4, fpos2);
          }
        }
      }

      let modeLetter = 'W';
      if (FEATURE_MODE === 'PAID_APP') modeLetter = 'F';
      else if (FEATURE_MODE === 'FREE_APP') modeLetter = 'B';

      return (
        <div style={{ padding: 10, textAlign: 'center', marginTop: 'env(safe-area-inset-top)' }}>
          <h2>{loc.somethingWrong}</h2>
          <p>{loc.somethingWrongDesc}</p>
          <p>{`${error.name} ${file}/${line}/${col}: ${error.message}`}</p>
          <Button
            color="secondary"
            variant="contained"
            component="a"
            href={`mailto:${email}?subject=Error%20${file}/${line}/${col}%20v${appVersion}${modeLetter}&body=${encodeURI(
              error.message,
            )}`}
            style={{ marginRight: 4 }}
          >
            {loc.emailMe}
          </Button>
          <Button color="primary" variant="contained" onClick={() => window.location.reload()}>
            {loc.continue}
          </Button>
          <Container
            style={hideStack < 3 ? { opacity: 0 } : {}}
            onClick={() => this.setState({ hideStack: hideStack + 1 })}
          >
            {error.stack}
          </Container>
        </div>
      );
    }

    return children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired, // from props
  // theme: PropTypes.objectOf(PropTypes.any).isRequired,
};
